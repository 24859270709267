<template>
    <div class="md:tw-col-span-3 tw-col-span-12 tw-rounded">
      <label
        for="countries"
        class="tw-block tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900"
        >Source</label
      >
  
      <select
        @change="
          (e) => $emit('update', { ...filters, source: e.target.value })
        "
        :value="filters.source"
        class="tw-bg-gray-50 tw-capitalize tw-border-solid tw-outline-none tw-border tw-border-gray-300 tw-text-gray-900 tw-text-sm tw-rounded-lg focus:tw-ring-orange-500 focus:tw-border-orange-500 tw-block tw-w-full tw-p-2.5"
      >
        <option value="all">All</option>
        <option
        :value="source"
        v-for="source in sources"
        :key="source"
        class="tw-capitalize"
      >
        {{ source }}
         </option>
      </select>
    </div>
  </template>
  
  <script>
  
  export default {
  
      props: {
          filters: {
              required: true,
          }
      },
  
      data() {
          return {
            sources: ['facebook', 'google', 'tiktok','snapchat'],
          }
      },
  }
  </script>
  
  <style>
  </style>