<template>
  <div class="tw-min-h-[500px] tw-flex tw-items-center tw-justify-center tw-bg-white tw-rounded-md tw-border ">
    <div class="tw-w-[40px] tw-h-[40px] tw-animate-spin loading tw-border-4 tw-border-x-transparent tw-border-t-transparent tw-rounded-full ">

    </div>

  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.loading {
    /* border-bottom-color: rgb(var(--v-theme-primary-color)); */
    /* border-right-color: rgb(var(--v-theme-primary-color)) !important; */
    border-bottom-color: #FF9F43 !important;
    border-right-color: #FF9F43 !important;
}


</style>