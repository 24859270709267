
export const localUrl = 'https://vldo.shop/'
export const serverUrl = 'https://api.vldo.shop/'


// export const localUrl = 'http://localhost:8080/'
// export const serverUrl = 'http://localhost:8000/'

export const version = '1.0.1';

export const currency = "$";
export const prefix = 'vlds';
export const adsSources = [ 'facebook', 'google', 'tiktok', 'snapchat' ];