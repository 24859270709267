export const fields = [
    {
        name: "Client",
        key: "fullname",
    },
    {
        name: "Phone",
        key: "phone",
    },
    {
        name: "Address",
        key: "adresse",
    },
    {
        name: "City",
        key: "city",
    },
];