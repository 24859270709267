<template>
  <div class="tw-flex tw-justify-center tw-items-center">
        <div class="tw-relative tw-w-8 tw-h-8 tw-animate-spin tw-rounded-full tw-bg-gradient-to-r tw-from-purple-400 tw-via-blue-500 tw-to-red-400 ">
            <div class="tw-absolute tw-top-1/2 tw-left-1/2 tw-transform tw--translate-x-1/2 tw--translate-y-1/2 tw-w-7 tw-h-7 tw-bg-white tw-rounded-full tw-border-2 tw-border-white"></div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>