<template>
  <div>
    
    <div class="tw-relative tw-min-h-[400px] tw-overflow-x-auto tw-overflow-y-visible  sm:tw-rounded-lg">
        <table class="tw-w-full tw-text-sm tw-text-left tw-text-gray-500">
            <thead class="tw-text-xs tw-text-gray-700 tw-uppercase tw-bg-gray-50">
                <tr>
                    <th scope="col" class="tw-p-4">
                        <div class="tw-flex tw-items-center">
                            <input id="checkbox-all-search" type="checkbox" class="tw-w-4 tw-h-4 tw-text-blue-600 tw-bg-gray-100 tw-border-gray-300 tw-rounded focus:tw-ring-blue-500 focus:tw-ring-2">
                            <label for="checkbox-all-search" class="tw-sr-only">checkbox</label>
                        </div>
                    </th>
                    <th v-for="column in columns" :key="column.prop" scope="col" class="tw-px-6 tw-py-3 text-truncate">
                        <div class="tw-w-fit tw-flex tw-whitespace-nowrap">
                            {{ column.name }}
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(movement, i) in movements" :key="i" class="tw-bg-white tw-border-b tw-whitespace-nowrap hover:tw-bg-gray-50">
                    <td class="tw-w-4 tw-p-4">
                        <div class="tw-flex tw-items-center">
                            <input id="checkbox-table-search-1" type="checkbox" class="tw-w-4 tw-h-4 tw-text-blue-600 tw-bg-gray-100 tw-border-gray-300 tw-rounded focus:tw-ring-blue-500   focus:tw-ring-2 ">
                            <label for="checkbox-table-search-1" class="tw-sr-only">checkbox</label>
                        </div>
                    </td>
                    <th scope="row" class="tw-px-6 tw-py-4 tw-font-medium tw-text-gray-900 tw-whitespace-nowrap ">
                        {{ movement.id }}
                    </th>
                    <td class="tw-px-6 tw-py-4">
                        {{ movement.created_at?.split('T')[0] }} <br/> {{ movement.created_at?.split('T')[1].split('.')[0] }}
                    </td>
                    <td class="tw-px-6 tw-py-4">
                        {{ movement.delivery.firstname + ' ' + movement.delivery.lastname }}
                    </td>
                    <td class="tw-px-6 tw-py-4">
                        <router-link :to="'products/' + movement.product.id">
                            {{ movement.product.name }}
                        </router-link>
                    </td>
                    <td class="tw-px-6 tw-py-4">
                        {{ quantityTotal(movement.inventory_movement_variations) }}
                    </td>
                    <td class="tw-px-6 tw-py-4">
                        <div :class="[movement.is_received ? 'tw-text-green-500 tw-bg-green-500/10' : 'tw-text-red-500 tw-bg-red-500/10' ]" class="tw-flex tw-justify-center tw-py-1 tw-rounded-lg tw-items-center">
                            <span>{{ movement.is_received ? 'Yes' : 'No' }}</span>
                        </div>
                    </td>
                    <td class="tw-px-6 tw-py-4">
                        <div class="tw-w-[200px] !tw-whitespace-normal">
                            {{ !!movement.note ? movement.note : '-' }}
                        </div>
                    </td>
                    <td class="tw-px-6 tw-py-4">
                        {{ movement.delivery.city?.name }}
                    </td>
                    
                    <td class="tw-px-6 tw-py-4">
                        <InventoryMovementsActions :movement="movement" />
                    </td>
                </tr>
                
            </tbody>
        </table>
    </div>


        <!-- Pagination -->
        <div class="mt-5 tw-grid tw-grid-cols-12 tw-gap-5">
            <div class="tw-col-span-12 md:tw-col-span-6 d-flex align-center">
                <div class="text-body-2 tw-h-fit mr-2 tw-text-zinc-700 tw-whitespace-nowrap">Show per page: </div>
                <select v-model="paginationLimit" class="focus:tw-border-orange-400 tw-border-solid tw-w-[70px] ml-2 px-2 tw-py-2 tw-rounded-md tw-border tw-flex tw-items-center">
                    <option :value="o" :key="o" v-for="o in allowedLimit">{{ o }}</option>
                </select>
            </div>
            <div class="tw-col-span-12 md:tw-col-span-6 d-flex tw-justify-end tw-flex-wrap align-center">
                <div class="text-caption tw-h-fit mr-2 font-weight-bold tw-text-zinc-700">{{ prevRange + 1 }} - {{ (currentPage == pageCount ?  movements.length : nextRange) }} of {{  movements.length }} items </div>
                <div>
                <v-btn @click="currentPage = n" :ripple="false" variant="flat" class="mr-1" icon rounded="lg" :color="n == currentPage ? 'primary-color' : 'grey'" density="comfortable"  v-for="n in pageCount" :key="n">
                    <span class="tw-text-white">{{ n }}</span>
                </v-btn>
                </div>
            </div>
        </div>
        <!-- /Pagination -->
  </div>
</template>

<script>
import InventoryMovementsActions from './InventoryMovementsActions.vue'
InventoryMovementsActions
export default {
    props: [ 'columns', 'movements' ],

    components: { InventoryMovementsActions },

    data() {
        return {
            allowedLimit: [5, 10, 20, 50, 100],
            currentPage: 1,
            paginationLimit: 10,
        }
    },

    computed: {
        prevRange() {
            return (this.currentPage - 1) * this.paginationLimit
        },

        nextRange() {
            return (this.currentPage) * this.paginationLimit
        },
        pageCount() {
            return Math.ceil(this.movements.length / this.paginationLimit)
        },
        items() {
            return this.movements.slice(this.prevRange, this.nextRange)
        }
    },

    methods: {
        quantityTotal(variations) {
            let qty = 0;
            variations.forEach(
                i => {
                qty += i.quantity
                }
            )

            return qty
        }
    }
}
</script>

<style>

</style>